import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  PageNav,
} from 'layouts';
import {
  Banner,
  Breadcrumbs,
  PersonCard,
} from 'components';
import { PAGES, BASE_URL } from 'config';
import { FORMAT_GRAPH_DATA } from 'utilities';

const BREADCRUMBS = [
  {
    name: 'About Us',
    absolute_slug: PAGES.ABOUT_BRAND.PATH,
  },
  {
    name: PAGES.ABOUT_PEOPLE.TITLE,
    absolute_slug: PAGES.ABOUT_PEOPLE.PATH,
  },
];

const CATEGORIES = [
  {
    value: 'all',
    title: 'All Team',
  },
  {
    value: 'adventure',
    title: 'Adventure',
  },
  {
    value: 'snow',
    title: 'Snow',
  },
  {
    value: 'haka_plus',
    title: 'Haka Plus',
  },
  {
    value: 'head_office',
    title: 'Head Office',
  },
];

class AboutPeoplePage extends PureComponent {
  constructor() {
    super();
    this.state = {
      selected: 'all',
    };
  }

  render() {
    const { data } = this.props;
    const { selected } = this.state;
    const OFFICE_PEOPLE = FORMAT_GRAPH_DATA(data.allOfficeCrew);
    const PEOPLE = FORMAT_GRAPH_DATA(data.allGuides);
    const EVERYONE = OFFICE_PEOPLE.concat(PEOPLE)
      .filter(person => selected === 'all' || person[selected]);

    return (
      <Layout page="about/brand">

        <Helmet
          title="Meet Our Amazing Tour Guides and Head Office Staff | Haka Tours, NZ "
          meta={[
            {
              name: 'description', content: `At Haka Tours we believe it’s the passion, energy and expertise of our local guides that truly sets us apart.
              They’re our heroes who help you discover all the highlights and hidden gems of your New Zealand Tour. Behind the scenes, the Haka HQ staff work equally as hard to give you the best experience.`,
            },
            { name: 'og:title', content: 'Meet Our Amazing Tour Guides and Head Office Staff | Haka Tours, NZ ' },
            {
              name: 'og:description', content: `At Haka Tours we believe it’s the passion, energy and expertise of our local guides that truly sets us apart.
              They’re our heroes who help you discover all the highlights and hidden gems of your New Zealand Tour. Behind the scenes, the Haka HQ staff work equally as hard to give you the best experience.`,
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/our-people/` }]}
        />

        <Banner backgroundImage="https://hakatours-staging.imgix.net/000/our-people-banner.jpg?fit=crop&auto=format" modifier="bg-position-30">
          <h2 className="c-heading c-heading--h0">Our People</h2>
        </Banner>
        <PageNav pages={[PAGES.ABOUT_TRIPS, PAGES.ABOUT_STORY, PAGES.ABOUT_PURPOSE, PAGES.ABOUT_PEOPLE, PAGES.ABOUT_AWARDS, PAGES.ABOUT_FAQS, PAGES.ABOUT_REVIEWS]} />
        <section className="l-section l-people-intro">
          <div className="l-container">
            <Breadcrumbs list={BREADCRUMBS} />
          </div>
          <div className="l-container l-container--small u-text--center">
            <h1 className="c-heading c-heading--h2">Our People</h1>
            <p>
              We believe it’s the passion, energy and expertise of our local guides that truly sets Haka Tours apart.
              They’re our heroes and they get a huge buzz out of helping you discover all the highlights and hidden gems and in delivering a 100% authentic New Zealand experience.
              When you join a Haka Tour you become part of the Haka whanau (family).
            </p>
          </div>
        </section>
        <section className="l-people-list">
          <div className="l-people-list__filters">
            <ul className="u-reset-list l-people-list__filter-list">
              {CATEGORIES.map(cat => (
                <li key={cat.value}>
                  <button
                    type="button"
                    className={`c-pill-btn ${selected === cat.value ? 'is-selected' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        selected: cat.value,
                      });
                    }}
                  >{cat.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="l-people-list__grid">
            {EVERYONE.map(person => <PersonCard key={person.id} person={person} />)}
          </div>
        </section>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allGuides {
      edges {
        node {
          id
          api_id
          name
          adventure
          snow
          mtb
          haka_plus
          image_url
          order
          description_markdown
        }
      }
    }

    allOfficeCrew {
      edges {
        node {
          id
          api_id
          name
          image_url
          head_office
          description_markdown
        }
      }
    }
  }
`;

AboutPeoplePage.propTypes = {
  data: PropTypes.any.isRequired,
};

export default AboutPeoplePage;
